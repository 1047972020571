#nav {
    backdrop-filter: blur(14px);
    background-color: rgba(255, 255, 255, 0.59);
    width: 100%;
    height: 4em;
    top: 0;
    position: absolute;
    border-bottom: 0.5px solid black;
    background: #496569;
}

.navLink {
    color: white !important;
}

.navOffset {
    padding-top: 4em;
}

.navSelected {
    font-weight: bold !important;
    color: rgb(255, 255, 255) !important;
}