:root {
    body {
        font-family: Dosis;
    }

    h1,
    h2 {
        font-variant-caps: all-petite-caps;

    }

    h1 {
        letter-spacing: 1pt;
        font-size: 5em;
    }

    h2 {
        font-weight: bold;
        font-size: 4em;
    }
}