@use '../media-queries.scss' as *;

#videos {
    height: 100vh;
    background: #fffcf4;

    .stack {
        padding-top: 5vh;

        .videoRow {
            display: flex;
            align-content: center;
            align-items: center;
            padding-right: 5vw;
            padding-left: 5vw;
            justify-content: space-between;
            flex-direction: row;

            @include sm {
                justify-content: center;
                margin-top: 2rem;
            }

            @include md {
                padding-right: 15vw;
                padding-left: 15vw;
            }

            h2 {
                font-size: 2em;

                @include sm {
                    font-size: 1em;
                    padding: 0;
                    margin: 0;
                }
            }

            p {
                margin: 0;
                padding: 0;
            }

            .text {
                @include sm {
                    display: none;
                }
            }

            .textRight {
                padding-left: 1em;
                padding: 1em;

            }

            .textLeft {
                padding-right: 1em;
                padding: 1em;

            }



        }
    }

}