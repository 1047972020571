.scrollDown {
    display: flex;
    font-size: 3em;
    position: absolute;
    bottom: 0;
    width: 100vw;
    align-items: center;
    color: #8080806e;
    gap: 40vw;
    justify-content: center;
    padding-bottom: 0.2em;
}

.hide {
    display: none;
}