.aboutContainer {
    background: #fffcf4;
    background: center;
    height: 100vh;
    background-repeat: no-repeat, repeat;


    .aboutText {
        padding-top: 20vh;

        padding-right: 10vw;
        padding-left: 10vw;

        h2 {
            position: relative;
        }

        p {
            padding-top: 1em;
            border-radius: 1em;
            position: relative;
            font-size: 1.2rem;
        }

        span {
            font-size: .7em;
        }

    }
}