#contact {

    h2 {
        text-align: center;
        text-shadow: 2px 2px #ffffff;
    }

    #myVideo {
        z-index: -10;
        position: absolute;
        height: 100%;
    }

    .text {
        padding-top: 10vh;
    }

    .cardsContainer {
        display: flex;
        justify-content: center;
        padding-top: 2em;
        align-items: center;

        .card {
            display: flex;
            align-items: center;
            align-content: center;
            padding: 2rem;
            margin-top: 3rem;
            flex-direction: column;
            background: #fffcf4;
            justify-content: center;

            .contactMethods {
                display: flex;
                gap: 3rem;

                .contact {
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    flex-direction: column;
                    align-items: center;
                    margin: 1em;

                    .icon {
                        font-size: 6em;
                        color: #496669;
                    }
                }
            }
        }

    }

}