#container {
    width: 100%;

    #landing {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 1;

        .continueButton {
            bottom: 30%;
            width: 10em;
            height: 3em;
            font-variant: all-petite-caps;
            position: absolute;
        }

        h1 {
            position: relative;
            color: #fff;
        }

        .titleSection {
            padding: 1em;

            .name {
                text-transform: uppercase;
                font-size: 5em;
                margin: 0;
            }

            .subtitle {
                font-size: 3em;
                text-align: center;
            }
        }


        .triangle {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: black;
            // background: radial-gradient(ellipse at bottom, #121212 0%, #000000 100%);
            // background: linear-gradient(to top, #ffffff 0%, #000000 100%);
            transition: all .8s cubic-bezier(0.645, 0.045, 0.355, 1);
            display: flex;
            justify-content: center;
            align-items: center;
            pointer-events: none;

            &.left {
                clip-path: polygon(0 0, 0% 100%, 100% 100%);
            }

            &.right {
                clip-path: polygon(100% 0, 0 0, 100% 100%);
            }
        }


        &.open {

            .continueButton {
                animation: fadeOut 1s 1 linear forwards;
            }

            .triangle {

                &.left {
                    clip-path: polygon(0 0, 0 100%, 0 100%);
                }

                &.right {
                    clip-path: polygon(100% 0, 100% 0, 100% 100%);
                }
            }


        }
    }


    @keyframes fadeOut {
        0% {
            opacity: 1;
        }

        50% {
            opacity: 0;
        }

        100% {
            opacity: 0;
            display: none;
        }
    }

    .hide {
        display: none;
    }
}