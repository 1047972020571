// from https://github.com/Necromancerx/media-queries-scss-mixins/blob/master/media-queries.scss
// media aliases and breakpoints

$screen-md: 900px;



@mixin sm {
    @media screen and (max-width: #{$screen-md}) {
        @content;
    }
}

@mixin md {
    @media screen and (min-width: #{$screen-md}) {
        @content;
    }
}